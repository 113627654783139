import React from "react"
import { Link, graphql } from "gatsby"
import {
  BlogArticleImage,
  BlogArticleListItem,
  BlogArticleListWrapper,
  BlogArticleMeta,
  BlogArticleText,
  BlogArticleTitle,
  BlogArticleSubtitle,
  Callout,
  GridContainer,
  GridSeparator,
  makeRem,
} from "@thedgbrt/logology-ui-components"
import SEO from "../components/seo"

export default ({ data }) => {
  const post = data.prismicBlogPost.data
  const author = post.author.document ? post.author.document.data.author : ""
  const categories = post.categories
  const featuredImage = post.featured_image.url
  const publishDate = post.publish_date

  const listCategories = categories => {
    if (categories.length < 1) return ""

    let list = []

    categories.map((el, index) => {
      const cat = el.category.document
      if (index > 0) list.push(", ")
      if (cat === null) return ""
      list.push(
        <Link to={`/blog/category/${cat.uid}`}>{cat.data.category}</Link>
      )
      return ""
    })

    return list
  }

  const getFirstCategory = categories => {
    if (categories.length < 1) return ""
    let cat = categories[0].category.document
    if (cat === null) return ""
    return cat.data.category
  }

  const renderSlice = (data, index) => {
    const { slice_type } = data

    if (slice_type === "body_text") {
      console.log(data.primary.text.html)
      return (
        <BlogArticleText
          html={data.primary.text.html}
          title={data.primary.optional_title.text}
          key={index}
        />
      )
    } else if (slice_type === "secondary_title") {
      return (
        <BlogArticleSubtitle
          title={data.primary.secondary_title.text}
          key={index}
        />
      )
    } else if (slice_type === "list") {
      return (
        <BlogArticleListWrapper key={index}>
          {data.items.map((el, index) => (
            <BlogArticleListItem html={el.list_item.html} key={index} />
          ))}
        </BlogArticleListWrapper>
      )
    } else if (slice_type === "image" || slice_type === "image_with_legend") {
      const { dimensions } = data.primary.image
      return (
        <BlogArticleImage
          alt={data.primary.image.alt}
          legend={
            slice_type === "image_with_legend" ? data.primary.legend.html : ""
          }
          src={data.primary.image.url}
          height={dimensions ? dimensions.height : ""}
          width={dimensions ? dimensions.width : ""}
          key={index}
        />
      )
    }

    return
  }

  return (
    <>
      <SEO
        description={post.seo_description.text}
        title={post.title.text}
        image={featuredImage}
        article={{
          section: getFirstCategory(categories),
          created_time: post.publish_date_iso,
          updated_time: data.prismicBlogPost.last_publication_date,
        }}
      />
      <BlogArticleMeta linkBack="/blog/">
        Written on {publishDate} by {author}. In {listCategories(categories)}.
      </BlogArticleMeta>

      <BlogArticleTitle title={post.title.text} />

      {post.body.map((slice, index) => renderSlice(slice, index))}

      <GridContainer
        rowsDesktop={[makeRem(90)]}
        rowsMobile={[makeRem(60)]}
        rowsTablet={[makeRem(80)]}
      />

      <GridSeparator />
      <GridContainer type={5}>
        <Callout
          colSpans={[[2, 8], [2, 8], [4, 11]]}
          buttonHref="/questionnaire/"
          buttonText="see the logos that match my values →"
          title="Take the brand discovery questionnaire and see your logos."
          type={5}
        />
      </GridContainer>
      <GridSeparator />
    </>
  )
}

export const query = graphql`
  query($uid: String!) {
    prismicBlogPost(uid: { eq: $uid }) {
      uid
      last_publication_date(formatString: "YYYY-MM-DDTHH:mm:ss.sssZ")
      data {
        publish_date(formatString: "MMMM Do, YYYY")
        publish_date_iso: publish_date(formatString: "YYYY-MM-DDTHH:mm:ss.sssZ")
        featured_image {
          url
        }
        title {
          text
        }
        seo_description {
          text
        }
        body {
          ... on PrismicBlogPostBodyBodyText {
            slice_type
            primary {
              text {
                html
              }
              optional_title {
                text
              }
            }
          }
          ... on PrismicBlogPostBodySecondaryTitle {
            slice_type
            primary {
              secondary_title {
                text
              }
            }
          }
          ... on PrismicBlogPostBodyImage {
            slice_type
            primary {
              image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicBlogPostBodyImageWithLegend {
            slice_type
            primary {
              image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              legend {
                html
              }
            }
          }
          ... on PrismicBlogPostBodyList {
            slice_type
            items {
              list_item {
                html
              }
            }
          }
        }

        author {
          document {
            ... on PrismicAuthor {
              data {
                author
              }
              uid
            }
          }
        }

        categories {
          category {
            document {
              ... on PrismicCategory {
                data {
                  category
                }
                uid
              }
            }
          }
        }
      }
    }
  }
`
